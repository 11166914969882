<template>
  <div>
    <el-radio-group v-bind="$attrs" v-on="$listeners" v-model="code" @change="choicePlatform"
                    class="platforms-logo">
      <el-radio-button v-for="(platform) in platforms" :label="platform.code" :key="platform.code">
        <div class="platforms" :style="`line-height:${LineHeight};`">
          <el-image
              :style="`width:${ImgW};height:${ImgH};`"
              :src="`${platform.logo_url?platform.logo_url:'https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png'}`"
              fit="contain">
          </el-image>
          <span class="radio-title" :style="`font-size:${FontSize};`">{{ platform.name }}</span>
        </div>
      </el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  name: "PlatformsRadioGroup",
  data() {
    return {
      platforms: [],
      code: null,
    }
  },
  props: {
    platformCode: {
      type: String,
      default: ''
    },
    showAll: {
      type: Boolean,
      default: false
    },
    ImgH: {
      type: String,
      default() {
        return '16px'
      }
    },
    ImgW: {
      type: String,
      default() {
        return '14px'
      }
    },
    FontSize: {
      type: String,
      default() {
        return '12px'
      }
    },
    LineHeight: {
      type: String,
      default() {
        return '1'
      }
    },
    purpose: {
      type: String,
      default() {
        return 'is_data_entry'
      }
    }

  },
  watch: {
    // eslint-disable-next-line
    platformCode: function (newVal, oldVal) {
      this.code = newVal
    },
  },
  created() {

  },
  mounted() {
    this.getPlatforms()
  },
  methods: {
    choicePlatform(val) {
      // console.log('platform', val)
      this.$emit('choicePlatform', val)
    },
    async getPlatforms() {
      let allPlatform = {
        code: '',
        logo_url: 'https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png',
        name: '全平台'
      }

      //从缓存中读取上次的平台信息
      // let platformsJson = localStorage.getItem('platforms')
      // if (platformsJson && platformsJson.indexOf('{') > -1) {
      //   if (this.showAll)
      //     this.platforms = [allPlatform, ...JSON.parse(platformsJson)]
      //   else
      //     this.platforms = JSON.parse(platformsJson)
      // }

      // 获取最新平台信息
      let searchCondition = this.handleSearchCondition()
      let { list } = await this.$api.getPlatformAll(searchCondition)
      this.$emit('getPlatformsRange', list)
      if (this.showAll) {
        this.platforms = [allPlatform, ...list]
      } else {
        this.platforms = list
      }

      // 缓存平台信息
      localStorage.setItem('platforms', JSON.stringify(list))

      //默认第一个平台,单选
      if (!this.showAll) {
        // console.log('platformCode:', this.platformCode)
        if (this.platforms.length > 0) {
          this.code = list[0]['code']
          this.$emit('choicePlatform', this.code)
        }
      }

    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      condition.status = 1

      if (this.purpose === 'is_data_entry') {
        condition.is_data_entry = 1
      }

      if (this.purpose === 'is_plc_entry') {
        condition.is_plc_entry = 1
      }
      return condition
    },
  }
}
</script>
<style scoped>
.platforms-logo {
  line-height: 1;
}

.platforms {
  display: flex;
  flex-direction: row;
}

.radio-title {
  /*font-size: 12px;*/
  margin-left: 2px;
  font-weight: 600;
  line-height: 1.4;
}

</style>
<style>

</style>
