<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-form size="small" :inline="true">
          <el-form-item label="年份选择：">
            <span slot="label"><b>年份：</b></span>
            <el-date-picker v-model="searchCondition.year" type="year" format="yyyy" value-format="yyyy"
              placeholder="选择年" @change="changeYear" clearable>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <span slot="label"><b>月份：</b></span>
            <el-date-picker v-model="ym" type="month" format="MM月" value-format="yyyy-MM" placeholder="选择月份"
              @change="changeMonth" clearable>
            </el-date-picker>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row>
      <el-col :xs="24" :sm="24" :lg="12">
        <div class="chart-wrapper">
          <pie-chart :chart-option="moneyChartOption" />
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :lg="12">
        <div class="chart-wrapper">
          <pie-chart :chart-option="execQtyChartOption" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PieChart from "@/pages/dashboard/PieChart";

export default {
  name: "PlatformPieChart",
  components: { PieChart },
  data() {
    return {
      moneyChartOption: {},
      execQtyChartOption: {},
      ym: null,
      searchCondition: {
        year: null, month: null, artist_id: null
      }
    }
  },
  props: {
    artistId: {
      type: String,
      require: true
    },
  },
  watch: {
    artistId(val) {
      this.searchCondition.artist_id = val
      this.initPieChart()
    }
  },
  created() {
    this.defaultTime()
  },
  mounted() {
    this.initPieChart()
  },
  methods: {
    defaultTime() {
      //月份选择：默认近6个月
      const end = new Date();

      //年份选择：默认今年
      this.searchCondition.year = end.getFullYear() + ''
    },
    changeYear() {
      this.searchCondition.month = ''
      this.initPieChart()
    },
    changeMonth() {
      let ym = this.ym
      if (ym) {
        let ymArr = ym.split('-')
        this.searchCondition.year = ymArr[0] + ''
        this.searchCondition.month = ymArr[1] + ''
      } else {
        this.searchCondition.month = null
      }

      this.initPieChart()
    },
    // 饼图
    async initPieChart() {
      let condition = this.handleSearchCondition()
      if (condition.year && condition.artist_id) {
        let { moneyChartOption, execQtyChartOption } = await this.$api.getRevenueItemPieChartData(condition)
        this.moneyChartOption = moneyChartOption;
        this.execQtyChartOption = execQtyChartOption;
      }
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.artistId) {
        condition.artist_id = this.artistId
      }

      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }

      if (this.searchCondition.month) {
        condition.month = this.searchCondition.month
      }
      return condition
    },
  }
}
</script>

<style scoped>
.chart-wrapper {
  margin: 10px;
}
</style>
