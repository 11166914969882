<template>
  <div>
    <echarts-chart :width="`100%`" :height="`500px`" :chart-option="chartOption"/>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import elementResizeDetector from 'element-resize-detector'
import { mapGetters } from 'vuex'
import EchartsChart from '@/components/EchartsChart'

export default {
  name: "StatisticsTrendChart",
  components: {
    echarts,
    elementResizeDetector, EchartsChart
  },
  data() {
    return {
      chartFlow: null,
      chartOption: {},
      // searchCondition: {
      //   time_value: null
      // },
    }
  },
  props: {
    title: {
      type: String,
      default() {
        return 'XXX增粉数据';
      }
    },
    searchCondition: {
      type: Object,
      default() {
        return {
          artist_id: '',
          platform_code: '',
          year: null
        }
      }
    },
    checked: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  watch: {
    searchCondition: {
      deep: true,
      handler() {
        this.getChartData()
      }
    },
    checked() {
      this.getChartData()
    }
  },
  computed: {
    ...mapGetters(['loadingStatus'])
  },
  methods: {
    appendBarChart(chartOption, title, data) {
      chartOption.legend.data.push(title)
      chartOption.series.push({
        name: title,
        type: 'bar',
        barWidth: 20,
        yAxisIndex: 0,
        data: data,
            label: {
              show: true, //开启显示
              position: 'top', //在上方显示
              textStyle: { //数值样式
                color: '#ff3176',
                fontSize: 12
              }
            },


      })
    },
    appendLineChart(chartOption, title, data) {
      chartOption.legend.data.push(title)
      chartOption.series.push({
        name: title,
        type: 'line',
        yAxisIndex: 0,
        data: data
      })
      return chartOption
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }

      if (this.searchCondition.platform_code) {
        condition.platform_code = this.searchCondition.platform_code
      }

      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }


      return condition
    },
    async getChartData() {
      let chartOption = {
        title: {
          text: this.title,
          top: 0,
          left: 'left',
        },
        tooltip: {
          trigger: 'axis',
          textStyle: {
            fontSize: 16
          }
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        grid: {
          top: 80,
        },
        legend: {
          data: [],//info.legend_data
          selected: [],//info.selected
          top: 40,
          textStyle: {
            fontWeight: 'bolder',
            fontSize: 16
          }
        },
        xAxis: [
          {
            type: 'category',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: {
          type: 'value'
        },
        series: [],//info.series
      }

      let searchCondition = this.handleSearchCondition()
      if (searchCondition.year && searchCondition.artist_id && searchCondition.platform_code) {
        let { series, selected } = await this.$api.getTrendDatumYearChartData(searchCondition)
        if (selected) {
          chartOption.legend.selected = selected
        }
        if (series) {
          series.forEach((item) => {
            this.appendBarChart(chartOption, item.name, item.data)
          })
        }
      }

      if (this.checked) {
        searchCondition.year = searchCondition.year - 1
        if (searchCondition.year && searchCondition.artist_id && searchCondition.platform_code) {
          let { series, selected } = await this.$api.getTrendDatumYearChartData(searchCondition)
          if (selected) {
            chartOption.legend.selected = { ...chartOption.legend.selected, ...selected }
          }
          if (series) {
            series.forEach((item) => {
              this.appendBarChart(chartOption, item.name, item.data)
            })
          }
        }
      }

      if (searchCondition.year && searchCondition.artist_id && searchCondition.platform_code)
        this.initChartData(chartOption)

    },
    initChartData(chartOption) {
      this.chartOption = chartOption
    },
  },
  mounted() {
    // this.initChart(this.optionData)
    this.getChartData()
  }
}
</script>

<style scoped>
#trend_chart {
  /*border: #0000BB dashed 1px;*/
  height: 600px;
  width: 80%;
  padding: 20px;
  color: #9f00bb;
}
</style>
