<template>
  <div>
      <el-row id="echarts-trend-data">
        <el-col :span="24">
          <div id="trend_chart"></div>
        </el-col>
      </el-row>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import elementResizeDetector from 'element-resize-detector'
import {mapGetters} from 'vuex'


export default {
  name: "StatisticsTrendChart",
  components: {
    echarts,
    elementResizeDetector
  },
  data() {
    return {
      chartFlow: null,
      // searchCondition: {
      //   time_value: null
      // },
    }
  },
  props: {
    title: {
      type: String,
      default() {
        return 'XXX增粉数据';
      }
    },
    searchCondition: {
      type: Object,
      default() {
        return {
          platform_code: null,
          artist_id: null,
          time_value: []
        }
      }
    },
  },
  watch: {
    searchCondition: {
      deep: true,
      handler(val) {
        // console.log('val',val)
        this.initChart(val)
      }
    }
  },
  computed: {
    ...mapGetters(['loadingStatus'])
  },
  methods: {
    initChart() {
      // this.searchCondition = searchCondition
      this.chartFlow = echarts.init(document.getElementById('trend_chart'))

      let condition = this.handleSearchCondition()
      this.loadChartData(condition)
      let erd = elementResizeDetector()
      let _this = this
      erd.listenTo(document.getElementById("echarts-trend-data"), function () {
        _this.chartFlow.resize()
      })
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }

      if (this.searchCondition.platform_code) {
        condition.platform_code = this.searchCondition.platform_code
      }

      if (this.searchCondition.time_value) {
        condition.start_time = this.searchCondition.time_value[0]
        condition.end_time = this.searchCondition.time_value[1]
      }

      return condition
    },
    async loadChartData(condition) {
      this.chartFlow.clear()
      // this.$store.commit('changeLoadingStatus', true)
      let info = await this.$api.getTrendDatumArtistDataChartData(condition)
      // this.$store.commit('changeLoadingStatus', false)
      // console.log('info', info)

      let option = {
        title: {
          text: this.title,
          top: 0,
          left: 'left',
        },
        tooltip: {
          trigger: 'axis',
          textStyle: {
            fontSize: 16
          }
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          top: 80,
        },
        legend: {
          data: info.legend_data,
          selected:info.selected,
          top: 40,
          textStyle: {
            fontWeight: 'bolder',
            fontSize: 16
          }
        },
        xAxis: {
          type: 'category',
          data: info.x_axis
        },
        yAxis: {
          type: 'value'
        },
        series: info.series
      }
      this.chartFlow.setOption(option)
    }
  },
  mounted() {
    this.initChart()
  }
}
</script>

<style scoped>
#trend_chart {
  /*border: #0000BB dashed 1px;*/
  height: 600px;
  width: 80%;
  padding: 20px;
  color: #9f00bb;
}
</style>
