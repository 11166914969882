<template>
    <div :class="className" :style="{height:height,width:width}"/>
    <!--  <div>-->
    <!--    <div :class="className" :style="{height:height,width:width}"/>-->
    <!--    <div> {{ width }} * {{ height }}</div>-->
    <!--    <div>{{ chartOption }}</div>-->
    <!--  </div>-->
</template>

<script>
import * as echarts from 'echarts'
import resize from './mixins/resize'

require('echarts/theme/macarons') // echarts theme

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '500px'
    },
    height: {
      type: String,
      default: '400px'
    },
    chartOption: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    chartOption: {
      deep: true,
      handler(val) {
        // console.log('val',val)
        this.setOptions(val)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
      // this.chart.setOption({
      //   title: {
      //     text: '金额占比',
      //     top: 0,
      //     left: 'left',
      //   },
      //   tooltip: {
      //     trigger: 'item',
      //     formatter: '{a} <br/>{b} : {c} ({d}%)'
      //   },
      //   toolbox: {
      //     feature: {
      //       saveAsImage: {}
      //     }
      //   },
      //   legend: {
      //     left: 'center',
      //     bottom: '10',
      //     data: ['抖音', '快手', '小红书', '微博', 'B站']
      //   },
      //   series: [
      //     {
      //       name: '各个平台金额占比',
      //       type: 'pie',
      //       roseType: 'radius',
      //       radius: [15, 95],
      //       center: ['50%', '38%'],
      //       data: [
      //         { value: 320, name: '抖音' },
      //         { value: 240, name: '快手' },
      //         { value: 149, name: '小红书' },
      //         { value: 100, name: '微博' },
      //         { value: 59, name: 'B站' }
      //       ],
      //       animationEasing: 'cubicInOut',
      //       animationDuration: 2600
      //     }
      //   ]
      // })
    },
    async setOptions(option) {
      let _t = this
      this.chart.setOption(option, true)
      let newKey = []
      if (this.chart) {
        this.chart.on('legendselectchanged', function(obj) {
          newKey = []
          for (let key in obj.selected) {
            option.legend.selected[key] = obj.selected[key]
            newKey = obj.selected
          }
          _t.$emit('chartSelect', newKey)
        })
      }
    }
  }
}
</script>
