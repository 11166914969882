<template>
  <div>
    <div>
      <el-form size="small" :inline="true">
        <el-form-item>
          <span class="title">【涨粉月数据】</span>
        </el-form-item>
        <el-form-item label="年份选择：">
          <span slot="label"><b>年份：</b></span>
          <el-date-picker
              v-model="formData.year"
              type="year"
              format="yyyy"
              value-format="yyyy"
              placeholder="选择年" @change="initList" clearable>
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="checked">对比去年</el-checkbox>
        </el-form-item>
      </el-form>
    </div>
    <div>
<!--      每月增粉趋势-->
      <StatisticsTrendYearChart :title="`每月增粉趋势`"
                                :searchCondition="condition"
                                :checked="checked"></StatisticsTrendYearChart>
    </div>
    <el-row>
      <el-col :span="24">
        <div class="default-table">
          <el-table
              :data="tableData"
              :row-class-name="rowClassName"
              :summary-method="getSummaries"
              show-summary
              border
              style="width: 100%">
            <el-table-column
                prop="ym"
                :label="`月份`"
                width="140" align="center">
            </el-table-column>
            <template v-for="(column,index) in columns">
              <el-table-column :prop="`${column.value}`" :key="index" :label="`${column.title}`"
                               :min-width="`${column.width}`" align="right" header-align="center">
                <template slot-scope="scope">
                        <span :class="`${column.className}`"
                              v-if="column.className==='money'">{{ moneyFormat(scope.row[column.value] || 0) }}
                        </span>
                  <span :class="`${column.className}`" v-else-if="column.className==='qty'">
                          {{ numberFormat(scope.row[column.value] || 0) }}</span>
                  <span :class="`${column.className}`" v-else>
                          {{ scope.row[column.value] || 0 }}
                        </span>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { numberFormat } from "@/utils/utils";
import StatisticsTrendYearChart from "@/pages/dashboard/trend/StatisticsTrendYearChart";

export default {
  name: "TrendMonthData",
  components: { StatisticsTrendYearChart },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {
          artist_id: '',
          platform_code: '',
          platform_name: '全平台',
        }
      }
    }
  },
  computed: {
    condition() {
      return {
        artist_id: this.searchCondition.artist_id,
        platform_code: this.searchCondition.platform_code,
        year: this.formData.year
      }
    }
  },
  data() {
    return {
      formData: {
        year: null,
      },
      tableData: [],
      columns: [
        { value: 'total_num', title: '总粉丝数', width: 100, className: 'qty' },
        { value: 'add_num', title: '新增粉丝数', width: 100, className: 'qty' },
        { value: 'content_add_num', title: '内容增粉数', width: 100, className: 'qty' },
        { value: 'promotion_add_num', title: '推广增粉数', width: 100, className: 'qty' },
        { value: 'promotion_cost', title: '涨粉投放', width: 100, className: 'money' },
        { value: 'price', title: '粉丝单价', width: 100, className: 'money' },
        { value: 'ad_repair', title: '广告维护', width: 100, className: 'money' },
        { value: 'live_preheat', title: '直播预热', width: 100, className: 'money' },
        { value: 'add_works_num', title: '新增作品数', width: 100, className: 'qty' },
        { value: 'total_works_num', title: '总作品数', width: 100, className: 'qty' },
      ],
      chartOptionData: {
        legendData: [],
        seriesData: []
      },
      checked: false,//对比去年
    }
  },
  watch: {
    'searchCondition.platform_code'(val, oldVal) {
      if (val !== oldVal) {
        this.initList()
      }
    }
  },
  created() {
    this.formData.year = (new Date()).getFullYear() + ''
  },
  mounted() {
    this.initList()
  },
  methods: {
    rowClassName({ row, rowIndex }) {
      // 把每一行的索引放进row.id
      row.index = rowIndex + 1
    },
    moneyFormat(value) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
    numberFormat(value) {
      return numberFormat(value, 0, '.', ',', 'round')
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      let price_index = null;// 涨粉单价的索引
      let promotion_cost_index = null;// 总推广花费的索引
      let promotion_add_num_index = null;// 总推广增粉数的索引
      let ad_repair_index = null//广告维护的索引
      let live_preheat_index = null//直播预热的索引
      columns.forEach((column, index) => {
        if (index < 0) {
          return
        }
        if (index === 0) {
          sums[index] = '合计:'
          return
        }
        // console.log(`${index}）${column.property}`)
        if (['add_num', 'content_add_num', 'promotion_add_num', 'promotion_cost', 'add_works_num','ad_repair','live_preheat'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev * 1 + curr * 1
              } else {
                return prev
              }
            }, 0)
            sums[index] += ''
          } else {
            sums[index] = 'N/A'
          }

          // // 格式化数据
          switch (column.property) {
            case 'promotion_cost':
              promotion_cost_index = index
              // console.log(`推广花费合计：${total_promotion_cost}`)
              // sums[index] = '¥' + this.moneyFormat(sums[index])
              break
            case 'promotion_add_num':
              promotion_add_num_index = index
              // console.log(`推广增粉合计：${total_promotion_add_num}`)
              break
            case 'ad_repair':
              ad_repair_index = index
              break
            case 'live_preheat':
              live_preheat_index = index
              break
            default:
              sums[index] = this.numberFormat(sums[index])
              break
          }
        }

        //涨粉单价列索引（）
        if (column.property === 'price') {
          price_index = index;
        }
      })

      // 涨粉单价=推广花费/推广涨粉数
      if (price_index !== null && promotion_add_num_index !== null && promotion_cost_index !== null) {
        sums[price_index] = sums[promotion_add_num_index] > 0 ? sums[promotion_cost_index] / sums[promotion_add_num_index] : '';
        //格式化
        sums[promotion_cost_index] = '¥' + this.moneyFormat(sums[promotion_cost_index])
        sums[price_index] = '¥' + this.moneyFormat(sums[price_index])
        sums[promotion_add_num_index] = this.numberFormat(sums[promotion_add_num_index])
        sums[ad_repair_index] = '¥' + this.moneyFormat(sums[ad_repair_index])
        sums[live_preheat_index] = '¥' + this.moneyFormat(sums[live_preheat_index])
      }
      return sums
    },
    calcChartData() {
      let legendData = ["总涨粉数", "新增粉数数", "内容增粉数"]
      let seriesData = []
      for (let i = 0; i < 12; i++) {
        let num = (i + 1) * 1000

        if (seriesData[`${ i + 1 }`])
          seriesData[`${ i + 1 }`]['data'].push(num)
        else
          seriesData[`${ i + 1 }`] = { 'title': '', data: [num] }

      }

      this.chartOptionData.legendData = legendData
      this.chartOptionData.seriesData = seriesData

    },
    async initList() {
      this.tableData = []
      let searchCondition = this.handleSearchCondition()
      if (searchCondition.year && searchCondition.artist_id && searchCondition.platform_code) {
        let { list } = await this.$api.getTrendDatumArtistMonthsData(searchCondition)
        // console.log(list)
        this.tableData = list;
        this.calcChartData()
      }
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }

      if (this.searchCondition.platform_code) {
        condition.platform_code = this.searchCondition.platform_code
      }
      if (this.formData.year) {
        condition.year = this.formData.year
      }


      return condition
    },
  }
}
</script>

<style scoped>
.title {
  font-weight: 600;
}
</style>
